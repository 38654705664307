import React from "react";
import "./Template.css";
import Header from "../Header";

interface TemplateProps {
    children: JSX.Element;
}

const Template: React.FC<TemplateProps> = (props) => {
    const { children } = props;

    return (
        <section className="template-container">
            <Header />
            <section className="template-children-container">{children}</section>
            <div className="template-footer">
                Powered by{" "}
                <a
                    className="template-footer-a"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bloxroute.com/"
                >
                    bloXroute Labs
                </a>
            </div>
        </section>
    );
};

export default Template;