import React from "react";
import { Option } from "models/types/common";
import { getLastDays } from "./time";
import { IValidators } from "models/interfaces/model";

export const initDaysOptions = (): Option[] => {
    const days = getLastDays(10);

    const res = days.reverse().map((day: string, i: number): Option => {
        return {
            value: (i + 1).toString(),
            label: <div>{day}</div>,
        };
    });
    return res;
};

export const initValidatorOptions = (validators: IValidators[]): Option[] => {
    const res = validators.map((validator: IValidators): Option => {
        return {
            value: validator.validator_name,
            label: <div>{validator.validator_name}</div>,
        };
    });
    return res;
};