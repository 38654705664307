import React from "react";
import "./LeaderboardTbody.css";
import { ILeaderboard } from "models/interfaces/model";
import { LeaderboardTbodyProps } from "models/types/props";
import { dateFormat } from "utils/time";
import EmptyBody from "../../EmptyBody";
import { formatToBNB, formatToBNB_Pow } from "utils/format";

const LeaderboardTbody: React.FC<LeaderboardTbodyProps> = ({ data, spread }) => {
    return (
        <tbody className="leaderboard-table-tbody">
            {data && data.length > 0 ? (
                data.map((d: ILeaderboard, i: number) => (
                    <tr key={i} className="leaderboard-table-tbody-tr">
                        <td className="leaderboard-table-td">
                            {dateFormat(d.timestamp.toString())}
                        </td>
                        <td className="leaderboard-table-td monospace-number">{formatToBNB_Pow(d.total_reward)}</td>
                        <td className="leaderboard-table-td monospace-number">{formatToBNB_Pow(d.bundle_reward)}</td>
                        <td className="leaderboard-table-td monospace-number">
                            {formatToBNB_Pow(d.private_tx_reward)}
                        </td>
                        <td className="leaderboard-table-td monospace-number">{formatToBNB(d.top_bundle_reward)}</td>
                        <td className="leaderboard-table-td">
                            <a
                                className="bundles-table-td-link"
                                href={`https://bscscan.com/block/${d.top_bundle_reward_block_number}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <i className="bi bi-box-arrow-up-right"></i>{" "}
                                {d.top_bundle_reward_block_number}
                            </a>
                        </td>
                    </tr>
                ))
            ) : (
                <EmptyBody columns={spread} />
            )}
            <tr className="leaderboard-table-tbody-tr">
                <td colSpan={spread} className="leaderboard-table-td"></td>
            </tr>
        </tbody>
    );
};

export default LeaderboardTbody;