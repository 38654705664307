import React from "react";
import { Link } from "wouter";
import { Nav, Navbar } from "react-bootstrap";
import "./Header.css";
import NavLink from "components/UI/Elements/NavLink";

const Header: React.FC = () => {
    return (
        <Navbar collapseOnSelect expand="xl" variant="dark" className="header-container">
            <Navbar.Brand>
                <Link href="/" className="header-logo">
                    <img
                        className="header-logo-image"
                        src="/bloxroute-logo-white.png"
                        alt="Bloxroute Labs"
                    ></img>
                    <div className="header-logo-text">bloXroute Block Explorer</div>
                </Link>
                {/* {process.env.REACT_APP_API_ENV != "prod" && (
                    <span className="header-testnet">TESTNET</span>
                )} */}
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" data-bs-target="#navbarScroll">
                <img
                    width="32px"
                    height="32px"
                    src="/burgerIcon.svg"
                    alt="menu burger icon"
                ></img>
            </Navbar.Toggle>

            <Navbar.Collapse id="navbarScroll" className="navbar-nav">
                <Nav>
                    <NavLink href="/">Blocks</NavLink>
                    <NavLink href="/leaderboard">Bundle Leaderboard</NavLink>
                    <NavLink href="/validator">Validator Rewards</NavLink>
                    <span className="navbar-divider">|</span>
                    <i
                        className="bi bi-arrow-clockwise header-reload ms-1"
                        onClick={() => window.location.reload()}
                    ></i>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;