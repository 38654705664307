import React, { useEffect } from "react";
import "./NumberInput.css";
import Select, { SingleValue } from "react-select";
import selectStyles from "./Selectstyle";
import { NumberInputProps } from "models/types/props";
import { Option } from "models/types/common";
import { getToday } from "utils/time";
import { initDaysOptions } from "utils/input";
import useSelectOption from "hooks/useSelectOption";

const NumberInput: React.FC<NumberInputProps> = ({ update }) => {
    const { options } = useSelectOption(initDaysOptions);

    const onSelectOption = (newValue: unknown) => {
        const option = newValue as SingleValue<Option>;
        if (option?.value) {
            const number: number = +option.value;
            update(number);
        }
    };

    useEffect(() => update(1), []);

    return (
        <section className="number-container">
            <Select
                options={options}
                defaultValue={{value: 1, label: getToday()}}
                styles={selectStyles}
                placeholder="Select date"
                onChange={onSelectOption}
            />
        </section>
    );
};

export default NumberInput;