import React, { useState } from "react";
import InspectModal from "../InspectModal";
import "./BundlesTable.css";
import BundlesTbody from "components/UI/Table/Tbody/BundlesTbody";
import Loading from "components/UI/Data/Loading";
import NoData from "components/UI/Data/NoData";
import { BundlesTableProps } from "models/types/props";
import { IBundle } from "models/interfaces/model";
import Table from "components/UI/Table";
import { TheadBundlesColumns } from "models/constants/table";
import { TableHeaderStyle_Block, TableStyle } from "models/style";
import useFetch from "hooks/useFetch";

const BundlesTable: React.FC<BundlesTableProps> = ({ isLoading, blockData }) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [blockNumber, setBlockNumber] = useState<number>(0);

    const { fetchData, isDataLoading, data, setData } = useFetch<IBundle>();

    const fetchBundleTxs = (timestamp: number) => {
        fetchData(`blocks/timestamp/${timestamp}/txs`);
    };

    if (isLoading) return <Loading />;

    if (!isLoading && blockData.length === 0) return <NoData />;

    return (
        <>
            <section className="bundles-table-container">
                <Table
                    columns={TheadBundlesColumns}
                    style={TableStyle}
                    headerStyle={TableHeaderStyle_Block}
                >
                    <BundlesTbody
                        fetchBundleTxs={fetchBundleTxs}
                        setBlockNumber={setBlockNumber}
                        setShowModal={setShowModal}
                        data={blockData}
                        spread={TheadBundlesColumns.columns[0].th.length}
                    />
                </Table>
            </section>
            <InspectModal
                show={showModal}
                txLoading={isDataLoading}
                blockNumber={blockNumber}
                txData={data}
                onHide={() => {
                    setShowModal(false);
                    setData([]);
                }}
            />
        </>
    );
};

export default BundlesTable;