import { API_URL } from "models/constants/common";
import { useState } from "react";

const useFetch = <TData,>() => {
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [data, setData] = useState<TData[]>([]);

    const fetchData = (url: string) => {
        setIsDataLoading(true);
        fetch(`${API_URL}${url}`)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setData(data);
                setIsDataLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setData([]);
                setIsDataLoading(false);
            });
    };

    return { fetchData, isDataLoading, data, setData };
};

export default useFetch;