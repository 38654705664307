import React, { useState, useEffect } from "react";
import SearchForm from "components/UI/Inputs/SearchForm";
import BundlesTable from "../../components/BundlesTable";
import "./MainPage.css";
import { IData } from "models/interfaces/model";
import useFetch from "hooks/useFetch";

const MainPage: React.FC = () => {
    const [blockNumber, setBlockNumber] = useState<string>("");

    const { fetchData, isDataLoading, data } = useFetch<IData>();

    useEffect(() => fetchData(`blocks/${blockNumber}`), [blockNumber]);

    return (
        <section className="main-container">
            <SearchForm isLoading={isDataLoading} update={setBlockNumber} />
            <BundlesTable isLoading={isDataLoading} blockData={data} />
        </section>
    );
};

export default MainPage;