import React from "react";
import NoData from "components/UI/Data/NoData";
import Loading from "components/UI/Data/Loading";
import "./ValidatorRewardsTable.css";
import { ValidatorRewardsTableProps } from "models/types/props";
import Table from "components/UI/Table";
import { TheadValidatorColumns } from "models/constants/table";
import ValidatorRewardsTbody from "components/UI/Table/Tbody/ValidatorRewardsTbody";
import { TableHeaderStyle_Leader, TableStyle } from "models/style";

const ValidatorRewardsTable: React.FC<ValidatorRewardsTableProps> = (props) => {
    const { day, data, isDataLoading } = props;

    if (isDataLoading && data.length === 0 && !day) {
        return (
            <section className="validator-table-container">
                <Table
                    columns={TheadValidatorColumns}
                    headerStyle={TableHeaderStyle_Leader}
                    style={TableStyle}
                >
                    <tbody className="validator-table-tbody">
                        <tr className="validator-table-tbody-tr">
                            <td
                                colSpan={5}
                                className="validator-table-td validator-table-td-center"
                            >
                                No day has been selected
                            </td>
                        </tr>
                        <tr className="validator-table-tbody-tr">
                            <td colSpan={5} className="validator-table-td"></td>
                        </tr>
                    </tbody>
                </Table>
            </section>
        );
    }
    if (isDataLoading && day) return <Loading />;
    if (!isDataLoading && day && data.length === 0) return <NoData />;

    return (
        <section className="validator-table-container">
            <Table
                columns={TheadValidatorColumns}
                headerStyle={TableHeaderStyle_Leader}
                style={TableStyle}
            >
                <ValidatorRewardsTbody
                    data={data}
                    spread={TheadValidatorColumns.columns[0].th.length}
                />
            </Table>
        </section>
    );
};

export default ValidatorRewardsTable;