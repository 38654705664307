import { useEffect, useState } from "react";
import { Option } from "models/types/common";

const useSelectOption = (initOptions: () => Option[]) => {
    const [options, setOptions] = useState<Option[]>([]);

    useEffect(() => {
        if (options.length === 0) {
            setOptions(initOptions());
        }
    }, []);

    return { options };
};

export default useSelectOption;