import React from "react";

export const TableHeaderStyle_Block: React.CSSProperties = {
    backgroundColor: "#D9D9D9",
};

export const TableHeaderStyle_Leader: React.CSSProperties = {
    padding: "15px 20px",
    minWidth: "150px",
    backgroundColor: "#D9D9D9",
    textAlign: "center"
};

export const TableHeaderStyle_Inspect: React.CSSProperties = {
    paddingLeft: 10,
    minWidth: "10vw",
    backgroundColor: "#D9D9D950",
};

export const TableStyle: React.CSSProperties = { overflow: "hidden", borderRadius: 10 };
