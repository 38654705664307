import React from "react";
import "./LeaderboardTable.css";
import Loading from "components/UI/Data/Loading";
import NoData from "components/UI/Data/NoData";
import { LeaderboardTableProps } from "models/types/props";
import Table from "components/UI/Table";
import { TheadLeaderColumns } from "models/constants/table";
import LeaderboardTbody from "components/UI/Table/Tbody/LeaderboardTbody";
import { TableHeaderStyle_Leader, TableStyle } from "models/style";

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ dataLoading, data }) => {

    if (dataLoading) return <Loading />;

    if (!dataLoading && data.length === 0) return <NoData />;

    return (
        <section className="leaderboard-table-container">
            <Table
                columns={TheadLeaderColumns}
                headerStyle={TableHeaderStyle_Leader}
                style={TableStyle}
            >
                <LeaderboardTbody data={data} spread={TheadLeaderColumns.columns[0].th.length}/>
            </Table>
        </section>
    );
};

export default LeaderboardTable;