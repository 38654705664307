import React from "react";
import "./InspectTbody.css";
import { InspectTbodyProps } from "models/types/props";
import { ITXData } from "models/interfaces/model";
import { minifyHash } from "utils/format";
import { calcGasPrice, calcRewardPow } from "utils/calc";

const InspectTbody: React.FC<InspectTbodyProps> = ({ data }) => {

    return (
        <tbody className="inspect-inner-table-tbody">
            {data.txs.map((tx: ITXData, j: number) => (
                <tr key={j} className="inspect-inner-table-tbody-tr">
                    <td className="inspect-inner-table-td">
                        <a
                            className="inspect-inner-table-td-link"
                            href={`https://bscscan.com/tx/${tx.tx_hash}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <i className="bi bi-box-arrow-up-right"></i> {minifyHash(tx.tx_hash)}
                        </a>
                    </td>
                    <td className="inspect-inner-table-td">
                        <a
                            className="inspect-inner-table-td-link"
                            href={`https://bscscan.com/address/${tx.sender.toLowerCase()}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <i className="bi bi-box-arrow-up-right"></i>{" "}
                            {minifyHash(tx.sender.toLowerCase())}
                        </a>
                    </td>
                    <td className="inspect-inner-table-td">
                        <a
                            className="inspect-inner-table-td-link"
                            href={`https://bscscan.com/address/${tx.to.toLowerCase()}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: "none" }}
                        >
                            <i className="bi bi-box-arrow-up-right"></i>{" "}
                            {minifyHash(tx.to.toLowerCase())}
                        </a>
                    </td>
                    <td className="inspect-inner-table-td monospace-number">{Number(tx.gas_used).toLocaleString()}</td>
                    <td className="inspect-inner-table-td monospace-number">{calcGasPrice(tx.gas_price)} gwei</td>
                    <td className="inspect-inner-table-td monospace-number">
                        {calcRewardPow(tx.validator_reward, 4)}
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default InspectTbody;