import React, { useState } from "react";
import "./SearchForm.css";
import { SearchFormProps } from "models/types/props";

const SearchForm: React.FC<SearchFormProps> = ({ isLoading, update }) => {

    const [blockNumber, setBlockNumber] = useState<string>("");

    return (
        <section className="search-container">
            <input
                className="search-input"
                type="text"
                placeholder="Search by Block Number"
                onChange={(e) => setBlockNumber(e.target.value)}
                onKeyUp={(e) => (e.key === "Enter" ? update(blockNumber) : null)}
            />
            <button className="search-btn" disabled={isLoading} onClick={() => update(blockNumber)}>
                <i className="bi bi-search"></i>
            </button>
        </section>
    );
};

export default SearchForm;