import React, { useEffect } from "react";
import LeaderboardTable from "../../components/LeaderboardTable";
import "./Leaderboard.css";
import { ILeaderboard } from "models/interfaces/model";
import useFetch from "hooks/useFetch";

const Leaderboard: React.FC = () => {
    const { fetchData, isDataLoading, data } = useFetch<ILeaderboard>();

    useEffect(() => fetchData("leaderboard"), []);

    return (
        <section className="leaderboard-container">
            <LeaderboardTable dataLoading={isDataLoading} data={data} />
        </section>
    );
};

export default Leaderboard;