import momentTZ from "moment-timezone";
import moment from "moment";

export const getToday = () => {
    const today = momentTZ().tz(momentTZ.tz.guess());
    const date = today.clone().subtract(1, "days");
    return date.format("YYYY-MM-DD");
};

export const getLastDays = (numberOfDays: number) => {
    const today = momentTZ().tz(momentTZ.tz.guess());
    const lastDays: string[] = [];

    for (let i = numberOfDays; i >= 1; i--) {
        const date = today.clone().subtract(i, "days");
        lastDays.push(date.format("YYYY-MM-DD"));
    }

    return lastDays;
};

export const renderAge = (ts: number): string => {
    const now = moment();
    const timeAgo = moment.unix(ts);
    const duration = moment.duration(now.diff(timeAgo));

    const days = Math.abs(duration.days());
    const hours = Math.abs(duration.hours());
    const minutes = Math.abs(duration.minutes());

    if (days > 0) {
        if (hours > 0) {
            return `${days} days and ${hours} hours ago`;
        } else {
            return `${days} days ago`;
        }
    } else if (hours > 0) {
        if (minutes > 0) {
            return `${hours} hours and ${minutes} minutes ago`;
        } else {
            return `${hours} hours ago`;
        }
    } else if (minutes > 0) {
        return `${minutes} minutes ago`;
    } else {
        return `Less than a minute ago`;
    }
};

export const dateFromTs = (ts: number): string => moment.unix(ts).utc().format("MMM Do YYYY");

export const dateFormat = (date: string): string => {
    const localTimezone = moment.tz.guess();
    return moment.tz(date, "YYYY-MM-DD", localTimezone).format("MMM Do YYYY");
};
