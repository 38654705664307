import React from "react";
import Modal from "react-bootstrap/Modal";
import InspectTbody from "components/UI/Table/Tbody/InspectTbody";
import "./InspectModal.css";
import { InspectModalProps } from "models/types/props";
import { IBundle } from "models/interfaces/model";
import Table from "components/UI/Table";
import { TheadInspectColumns } from "models/constants/table";
import Loading from "components/UI/Data/Loading";
import { TableHeaderStyle_Inspect } from "models/style";
import { calcRewardPow } from "utils/calc";

const InspectModal: React.FC<InspectModalProps> = ({
    show,
    blockNumber,
    txData,
    onHide,
    txLoading,
}) => {
    return (
        <Modal show={show} onHide={onHide} dialogClassName="my-modal">
            <Modal.Header closeButton>
                <Modal.Title>Bundles in #{blockNumber}</Modal.Title>
            </Modal.Header>
            <section className="inspect-container">
                {txData.length === 0 || txLoading ? (
                    <div className="inspect-loading-continar">
                        <Loading color="#222222" />
                    </div>
                ) : (
                    <Table columns={{ columns: [] }}>
                        <tbody>
                            {txData.map((bundle: IBundle, i: number) => (
                                <tr key={i} className="inspect-table-tr">
                                    {bundle.bundle_hash !== "" && (
                                        <td colSpan={6} className="inspect-table-td">
                                            <Table
                                                columns={TheadInspectColumns(
                                                    calcRewardPow(bundle.bundle_reward, 4),
                                                )}
                                                style={{
                                                    border: "2px solid #D9D9D950",
                                                    borderCollapse: "initial",
                                                }}
                                                headerStyle={TableHeaderStyle_Inspect}
                                            >
                                                <InspectTbody data={bundle}/>
                                            </Table>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
            </section>
        </Modal>
    );
};

export default InspectModal;