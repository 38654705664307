import { TheadProps } from "models/types/props";
import "./Thead.css";
import React from "react";
import { TrTableType } from "models/types/table";

const Thead: React.FC<TheadProps> = ({ columns, style }) => {
    return (
        <thead className="table-thead-thead" style={style}>
            {columns.columns.map((tr: TrTableType, i: number) => (
                <tr key={i} className="table-thead-tr">
                    {tr.th.map((th: string, j: number) => (
                        <th
                            colSpan={tr.spread}
                            key={j}
                            className={`table-thead-th ${tr.spread > 1 && "table-thead-th-center"}`}
                        >
                            {th}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );
};

export default Thead;