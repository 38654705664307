import React, { useEffect, useState } from "react";
import "./ValidatorRewards.css";
import ValidatorRewardsTable from "components/ValidatorRewardsTable";
import NumberInput from "components/UI/Inputs/NumberInput";
import { IValidatorRewards } from "models/interfaces/model";
import useFetch from "hooks/useFetch";
import useSelectOption from "hooks/useSelectOption";
import { initDaysOptions } from "utils/input";

const ValidatorRewards: React.FC = () => {
    const [day, setDay] = useState<number>(0);

    const { fetchData, isDataLoading, data } = useFetch<IValidatorRewards>();

    useEffect(() => {
        if (day) {
            fetchData(`validators/${day}`);
        }
    }, [day]);

    return (
        <section className="validator-container">
            <NumberInput update={setDay} />
            <ValidatorRewardsTable day={day} isDataLoading={isDataLoading} data={data} />
        </section>
    );
};

export default ValidatorRewards;