import React from "react";
import "./Loading.css"

const Loading: React.FC<{color?: string}> = ({color}) => {
    return (
        <div className="loading-continar" style={{color: color || "#FFFFFF50"}}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Loading;