import React from "react";
import "./NoData.css";

const NoData: React.FC = () => {
    return (
        <section className="not-found-continar">
            <section className="not-found-circle">
                <i className="bi bi-search not-found-search-icon"></i>
                <i className="bi bi-x not-found-x-icon"></i>
            </section>
            <div className="not-found-text">No items found</div>
        </section>
    );
};

export default NoData;