// @flow

import React from "react";
import "./Pagination.css";

type PaginationProps = {
    active: number;
    onChange: (page: number) => void;
    items: any;
    itemsPerPage: number;
};

function Pagination(props: PaginationProps) {
    const { active, onChange, items, itemsPerPage } = props;

    let startPage = active < 3 ? 1 : active - 2;
    const maxPages = Math.ceil(items.length / itemsPerPage);
    const pageLength = maxPages - startPage + 1;

    const pages = Array(pageLength)
        .fill([])
        .map(() => startPage++);

    if (items.length <= itemsPerPage) {
        return (
            <section className="pagination">
                <button className="previous" disabled={true}>{`<`}</button>
                <div className="number">{" "}</div>
                <button className="next" disabled={true}>{`>`}</button>
            </section>
        );
    }

    return (
        <section className="pagination">
            <button
                className={`previous ${active == 1 && "previous-start"}`}
                disabled={active == 1}
                onClick={() => onChange(active - 1)}
            >{`<`}</button>
            {pages.map((page) => (
                <button
                    key={`pagebutton${page}`}
                    className={active === page ? "number active" : "number"}
                    onClick={() => onChange(page)}
                >
                    {page}
                </button>
            ))}
            <button
                className={`next ${active >= maxPages && "next-end"}`}
                disabled={active >= maxPages}
                onClick={() => onChange(active + 1)}
            >{`>`}</button>
        </section>
    );
}

export default Pagination;
