import { TX_PER_PAGE } from "models/constants/common";
import { useEffect, useState } from "react";
import { inspectPageSlice } from "utils/slice";

const useSlice = <TData,>(data: TData[]) => {
    const [page, setPage] = useState<number>(1);
    const [sliceData, setSliceData] = useState<TData[] | undefined>(undefined);

    useEffect(() => {
        const slice = inspectPageSlice<TData>(data, page, TX_PER_PAGE);
        setSliceData(slice);
    }, [page]);

    return { page, setPage, sliceData };
};

export default useSlice;