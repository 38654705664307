import React from "react";
import Button from "react-bootstrap/Button";
import "./BundlesTbody.css";
import Pagination from "components/UI/Elements/Pagination";
import { IData } from "models/interfaces/model";
import { BundlesTbodyProps } from "models/types/props";
import { formatNumber, minifyHash } from "utils/format";
import { renderAge } from "utils/time";
import { calcRewardPow } from "utils/calc";
import { TX_PER_PAGE } from "models/constants/common";
import useSlice from "hooks/useSlice";
import EmptyBody from "../../EmptyBody";


const BundlesTbody: React.FC<BundlesTbodyProps> = ({
    fetchBundleTxs,
    setBlockNumber,
    setShowModal,
    data,
    spread,
}) => {
    const { page, setPage, sliceData } = useSlice<IData>(data);

    return (
        <tbody className="bundles-table-tbody">
            {sliceData && sliceData.length > 0 ? (
                sliceData.map((d: IData, i: number) => (
                    <tr key={i} className="bundles-table-tbody-tr">
                        <td className="bundles-table-td">
                            <a
                                className="bundles-table-td-link"
                                href={`https://bscscan.com/block/${d.block_number}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <i className="bi bi-box-arrow-up-right"></i> {d.block_number}
                            </a>
                        </td>
                        <td className="bundles-table-td monospace-number">{renderAge(d.timestamp)}</td>
                        <td className="bundles-table-td monospace-number">{minifyHash(d.block_hash)}</td>
                        <td className="bundles-table-td monospace-number">{formatNumber(d.mev_reward)}</td>
                        <td className="bundles-table-td monospace-number">
                            {d.private_reward ? calcRewardPow(d.private_reward) : null}
                        </td>
                        <td className="bundles-table-td">{d.miner}</td>
                        <td className="bundles-table-td monospace-number">{d.gas_used.toLocaleString()}</td>
                        <td className="bundles-table-td monospace-number">{d.miner_reward}</td>
                        <td className="bundles-table-td monospace-number">{d.tx_count}</td>
                        <td className="bundles-table-td monospace-number">{d.bundle_count}</td>
                        <td className="bundles-table-td monospace-number">
                            { d.signer ? minifyHash(d.signer.toLowerCase()) : null }
                        </td>
                        <td className="bundles-table-td">
                            <Button
                                variant="outline-dark"
                                disabled={d.bundle_count === 0}
                                onClick={() => {
                                    setBlockNumber(d.block_number);
                                    setShowModal(true);
                                    fetchBundleTxs(d.timestamp);
                                }}
                            >
                                <i className="bi bi-file-text-fill"></i>
                            </Button>
                        </td>
                    </tr>
                ))
            ) : (
                <EmptyBody columns={spread} />
            )}
            <tr className="bundles-table-tbody-tr">
                <td colSpan={spread} className="bundles-table-td">
                    <Pagination
                        active={page}
                        onChange={(page) => setPage(page)}
                        items={data}
                        itemsPerPage={TX_PER_PAGE}
                    />
                </td>
            </tr>
        </tbody>
    );
};

export default BundlesTbody;