import { EmptyBodyProps } from "models/types/props";
import React from "react";

const EmptyBody: React.FC<EmptyBodyProps> = ({ columns }) => {
    return (
        <tr>
            {Array.from({ length: columns }, (_: any, i: number) => (
                <td key={i} />
            ))}
        </tr>
    );
};

export default EmptyBody;