import React from "react";
import Thead from "./Thead";
import "./Table.css";
import { TableProps } from "models/types/props";

const Table: React.FC<TableProps> = ({ columns, children, style, headerStyle }) => {
    return (
        <table className="table-table" style={style}>
            {columns.columns.length !== 0 && (
                <Thead style={headerStyle} columns={columns} />
            )}
            {children}
        </table>
    );
};

export default Table;