import { calcReward, calcRewardPow } from "./calc";

export const formatNumber = (input?: number | string, decimal: number = 6): string => {
    if (!input || input === "" || input === "0" || input === 0) {
        return "";
    }

    const number = typeof input === "string" ? parseFloat(input) : input;

    const numStr = number.toString();
    const decimalIndex = numStr.indexOf(".");

    if (decimalIndex !== -1 && numStr.length - decimalIndex - 1 >= decimal) {
        return number.toFixed(decimal);
    }

    return number.toString();
};

export const validatorFormat = (validator: string) => {
    const regex = /^[0-9a-fA-F]{40}$/;
    if (regex.test(validator)) {
        return validator.toLowerCase();
    } else {
        return validator;
    }
};

export const minifyHash = (hash: string): string =>
    hash ? `${hash.slice(0, 6)}...${hash.slice(-6)}` : "0x0000...000000";

export const formatToBNB = (number: number | null | undefined): string | null =>
    number ? `${calcReward(number)} BNB` : null;

export const formatToBNB_Pow = (number: number | null | undefined): string | null =>
    number ? `${calcRewardPow(number)} BNB` : null;