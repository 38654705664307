import React from "react";
import "./ValidatorRewardsTbody.css";
import { ValidatorRewardsTbodyProps } from "models/types/props";
import { IValidatorRewards } from "models/interfaces/model";
import { calcRewardPow } from "utils/calc";
import { formatToBNB_Pow, validatorFormat } from "utils/format";
import EmptyBody from "../../EmptyBody";

const ValidatorRewardsTbody: React.FC<ValidatorRewardsTbodyProps> = ({ data, spread }) => {
    return (
        <tbody className="validator-table-tbody">
            {data && data.length > 0 ? (
                data.map((d: IValidatorRewards, i: number) => (
                    <tr key={i} className="validator-table-tbody-tr">
                        <td className="validator-table-td">{validatorFormat(d.validator)}</td>
                        <td className="validator-table-td monospace-number">{formatToBNB_Pow(d.total_reward)}</td>
                        <td className="validator-table-td monospace-number">{formatToBNB_Pow(d.bundle_reward)}</td>
                        <td className="validator-table-td monospace-number">
                            {formatToBNB_Pow(d.private_tx_reward)}
                        </td>
                    </tr>
                ))
            ) : (
                <EmptyBody columns={spread} />
            )}
            <tr className="validator-table-tbody-tr">
                <td colSpan={spread} className="validator-table-td"></td>
            </tr>
        </tbody>
    );
};

export default ValidatorRewardsTbody;