import { ColumnsTableType } from "models/types/table";

export const TheadBundlesColumns: ColumnsTableType = {
    columns: [
        {
            spread: 1,
            th: [
                "Block",
                "Age",
                "Hash",
                "Bundle Reward",
                "Private Tx Reward",
                "Validator",
                "Gas used",
                "Validator Reward",
                "TXs",
                "Bundles",
                "Signer",
                "Inspect",
            ],
        },
    ],
};

export const TheadInspectColumns = (reward: string): ColumnsTableType => {
    return {
        columns: [
            { spread: 6, th: [`Bundle Reward${" "}${reward}`] },
            {
                spread: 1,
                th: ["TX Hash", "Sender", "To", "Gas Used", "Gas Price", "Validator Reward"],
            },
        ],
    };
};
export const TheadLeaderColumns: ColumnsTableType = {
    columns: [
        {
            spread: 1,
            th: [
                "Date",
                "Total Reward",
                "Bundle Reward",
                "Private Tx Reward",
                "Top Bundle Reward",
                "Top Bundle Block",
            ],
        },
    ],
};
export const TheadValidatorColumns: ColumnsTableType = {
    columns: [
        {
            spread: 1,
            th: ["Validator", "Total Reward", "Bundle Reward", "Private Tx Reward"],
        },
    ],
};