import React from "react";
import { Switch, Route } from "wouter";
import Template from "./components/Template";
import MainPage from "./pages/MainPage";
import Leaderboard from "./pages/Leaderboard";
import ValidatorRewards from "./pages/ValidatorRewards";
import "./global-style.css";

const App: React.FC = () => {
    return (
        <Template>
            <Switch>
                <Route path='/leaderboard'>
                    <Leaderboard />
                </Route>
                <Route path='/validator'>
                    <ValidatorRewards />
                </Route>
                <Route>
                    <MainPage />
                </Route>
            </Switch>
        </Template>
    );
};

export default App;