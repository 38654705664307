import { StylesConfig } from "react-select";

const selectStyles: StylesConfig = {
    control: (styles, _) => ({
        ...styles,
        backgroundColor: "#EBEBEB",
        width: "200px",
        height: "48px",
        border: "1px solid rgba(0, 0, 0, 0.30)",
        borderRadius: 10,
        padding: "0 15px",
        fontSize: "1.1rem",
        boxShadow: "0px 6px 20px 0px rgba(0, 0, 0, 0.25)",
        "&:hover": {
            cursor: "pointer",
        },
    }),
    option: (provided, state) => ({
        ...provided,
        width: "100%",
        height: "100%",
        color: "#222222",
        backgroundColor: state.isFocused ? "#FEFEFE" : "#EBEBEB",
        cursor: state.isFocused ? "pointer" : "default",
        padding: "0.5em 3em 0.5em 2em",
        "&:active": {
            cursor: "pointer",
            backgroundColor: "#EBEBEB",
        },
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: "#222222",
        opacity: state.isDisabled ? 0.5 : 1,
        transition: "opacity 300ms",
    }),
    input: (styles) => ({ ...styles, color: "#222222" }),
    placeholder: (styles) => ({ ...styles, color: `#222222`, textAlign: "left" }),
    indicatorSeparator: () => ({ display: "none" }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: 290,
        overflow: "scroll",
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: "#EBEBEB",
        padding: "0.4em 0",
        border: "1px solid rgba(0, 0, 0, 0.30)",
        boxShadow: "0px 11px 22px rgba(0, 0, 0, 0.14)",
        borderRadius: "0 0 10px 10px",
        margin: "-6px 0 0 0",
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: "#A5A5A5",
        "&:hover": {
            cursor: "pointer",
            color: "#BfBfBf"
        },
    }),
};

export default selectStyles;