import React from "react";
import { useRoute, Link } from "wouter";
import "./NavLink.css";
import { NavLinkProps } from "models/types/props";

const NavLink: React.FC<NavLinkProps> = ({ children, href }) => {
    const [isActive] = useRoute(href);
    return (
        <Link href={href}>
            <a className={`navbar-link-a ${isActive ? "actives" : ""}`}>{children}</a>
        </Link>
    );
};

export default NavLink;